@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;1,100;1,300;1,400&display=swap');
$theme-colors: (
  'primary': #669eb7,
  'danger': #b95345,
  'success': #66b79e,
  'warning': #b79e66,
  'extra': #4477b5,
);
@import '~bootstrap/scss/bootstrap';

.App {
  font-family: Roboto;
  width: 100vw;
  h2 {
    text-transform: none;
  }
}

.login-container {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  margin-top: 40px;
  max-width: 450px;
  padding: 25px 40px;
}

.spinner-container {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  align-self: center;
  justify-self: center;
}

.calendar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  .week {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    font-size: 24px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    span {
      margin: 0 10px;
    }
    svg {
      color: theme-color('primary');
    }
  }
}
.calendar-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 0;
  position: relative;

  .calendar-grid {
    display: flex;
    flex-direction: column;
    width: 70px;
    margin-top: 25px;
    span {
      font-size: 12px;
      border-bottom: 1px solid #ebebeb;
      height: 18px;
      &.half {
        color: transparent;
        border-bottom: 1px solid #dfdfdf;
      }
      &:first-child {
        border-top: 1px solid #dfdfdf;
        height: 19px;
      }

      &.whole-day {
        height: 18px;
        display: inline-block;
      }
    }
  }
  .calendar-day {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #fafafa;
    &:nth-child(odd) {
      background: #fff;
      .event {
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
      }
      &:hover {
        background-color: #f3f3f3;
      }
    }
    &:nth-child(even) {
      background: #eaeaea;
      .event {
        border-right: 1px solid #eaeaea;
        border-bottom: 1px solid #eaeaea;
      }
      &:hover {
        background-color: #ddd;
      }
    }
    .heading {
      text-align: center;
      border-bottom: 1px solid #ddd;
      text-transform: capitalize;
      background-color: #fff;
    }
    .event {
      position: absolute;
      top: 30px;
      height: 57px;
      background-color: theme-color('primary');
      width: 100%;
      color: #fff;
      font-size: 14px;
      padding: 0 3px;
      overflow: hidden;
      cursor: pointer;
      z-index: 3;

      &.small {
        line-height: 18px;
        .data {
          display: inline;
        }
      }
      &.hover-event {
        z-index: 2;
        width: 60%;
        color: theme-color('success');
        background-color: #cceedd;
        border-top: 1px solid;
        border-bottom: 1px solid;
        pointer-events: none;
      }
      &.range-event {
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        white-space: nowrap;
        font-size: 11px;
        overflow: hidden;
        //    background-color: transparent;
        //
        //  &.passed {
        //    background-color: transparent;
        //  }

        .active-bar,
        .inactive-bar {
          position: absolute;
          top: 0;
          height: 100%;
          z-index: -1;
        }

        .active-bar {
          background-color: theme-color('primary');
        }

        &.personal-event {
          .active-bar {
            background-color: theme-color('extra');
          }
        }

        &.general-event {
          background-color: theme-color('warning');

          .active-bar {
            background-color: theme-color('warning');
          }
        }

        .inactive-bar {
          left: 0;
          background-color: #aaa;
        }

        .data {
          font-size: 11px;
          display: inline;
        }
        .end {
          position: absolute;
          background: inherit;
          box-sizing: border-box;
          border-bottom: none;
          height: 18px;
          padding-left: 0.5rem;
          right: 0px;
          padding-right: 3px;

          &:after {
            right: 2px;
            border-left: 5px solid #ffffff;
          }
        }
        .start {
          &:after {
            left: 2px;
            border-right: 5px solid #ffffff;
          }
        }
        .start,
        .end {
          &:after {
            content: '';
            position: absolute;
            border-bottom: 5px solid transparent;
            border-top: 5px solid transparent;
            opacity: 0;
            top: 50%;
            transform: translateY(-50%);
          }
          &.caret {
            &:after {
              opacity: 1;
            }
          }
        }
        .end.caret {
          padding-right: calc(0.5rem + 3px);
        }
        .start.caret {
          padding-left: 0.5rem;
        }
      }

      &.other-personal {
        z-index: 0;
        background: repeating-linear-gradient(
          45deg,
          mix(theme-color('primary'), #fff, 50%),
          mix(theme-color('primary'), #fff, 50%) 15px,
          mix(theme-color('primary'), #fff, 70%) 15px,
          mix(theme-color('primary'), #fff, 70%) 30px
        );

        .end {
          background: initial;
        }
      }

      &.passed {
        background-color: #aaa;
      }
      &.personal-event {
        width: 100%;
        background-color: theme-color('extra');
        .data {
          display: inline;
        }
      }
      &.new-event {
        width: 100%;
        background-color: theme-color('success');
        .data {
          display: inline;
        }
      }
      &.not-valid {
        color: white;
        background-color: red;
      }
      .time {
        padding-right: 5px;
      }
      .person {
        display: inline-block;
        padding-right: 5px;
      }
      .data {
        line-height: 14px;
        display: block;
        font-size: 12px;
      }
    }
    .hover-area {
      width: 40%;
      display: flex;
      flex: 1;
      align-self: flex-end;
      position: relative;
      z-index: 1;
      &.active {
        background-color: #cceedd;
      }
    }
  }
}
.veckoheader {
  display: flex;
  justify-content: space-between;
  .header {
    font-size: 1.75rem;
  }
  .hide-passed {
    display: flex;
    align-items: flex-end;
    font-weight: bold;
  }
}
.veckodetaljer {
  tr {
    display: flex;
    &.hide {
      display: none;
    }
    .cell-header {
      text-transform: capitalize;

      .range {
        display: block;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }
}
.radea-editor {
  .editors {
    border: 1px solid #ddd;
    padding: 2px;
    border-radius: 3px;
    min-height: 130px;
  }
  .btn {
    padding: 2px 8px;
    margin-right: 4px;
    margin-top: 4px;
    font-size: 12px;
  }
  .public-DraftEditor-content {
    max-height: 200px;
    overflow: auto;
  }
  &.readonly {
    .public-DraftEditor-content {
      max-height: none;
    }
    .editors {
      border: none;
      padding: 0;
      border-radius: 0;
    }
  }
}
span.test-span {
  line-height: 25px;
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

@media (max-width: 920px) {
  .calendar-container {
    max-width: 95vw;
    height: auto;
    .calendar-grid {
      min-width: 40px;
      background-color: #fff;
      span {
        text-align: center;
        font-weight: bold;
        border-top: 1px solid #666;
      }
    }
    .calendar-day {
      min-width: 200px;
    }
  }
  .veckodetaljer {
    tr {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1;
      background-color: rgba(0, 0, 0, 0.05);
      td.col-3,
      td.col-4,
      td.col-5 {
        width: 100%;
        max-width: 100%;
        display: flex;
        flex: 1;
        border-top: 2px solid theme-color('primary');
      }
      .cell-list {
        max-height: none;
        width: 100%;
      }
      th {
        display: none;
      }
      &.hide {
        display: none;
      }
    }
  }
}
@media (max-width: 640px) {
  .calendar-header {
    .week span {
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.list-wrapper {
  h2.heading,
  h2 {
    font-family: Roboto;
    color: #333;
    font-size: 2.3rem;
    margin: 1rem 0 2rem;
  }
}

.search-label {
  margin-bottom: 30px;
  border: 1px solid #dee2e6;
  padding: 4px 8px;
  border-radius: 4px;
  display: inline-flex;
  max-width: 320px;
  align-items: center;
  input {
    margin-left: 4px;
    border: 0;
    width: 320px;
    height: 30px;
    outline: none;
    font-size: 20px;
  }
}

.order-list {
  margin-bottom: 30px;
  border-top: 1px solid #ddd;
  margin-top: 15px;
  &.border-bottom {
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
  }
}

.cell-list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 380px;
  .cell-row {
    display: flex;
    flex-direction: column;
    padding: 1px 3px;
    margin-right: 1px;
    .cell-header {
      color: #fff;
      font-weight: bold;
      padding: 1px 7px;
      margin-bottom: 4px;
      &.gray {
        background-color: #999;
      }
      &.blue {
        background-color: theme-color('primary');
      }
    }
    .time {
      display: flex;
      align-items: center;
    }
    &.hide {
      display: none;
    }
  }
  &.colored {
    .cell-row:nth-child(odd) {
      background-color: #fff;
    }
    .cell-row:nth-child(even) {
      background-color: #e0e0e0;
    }
  }
}

.personal-lista {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 234px;
  overflow-y: scroll;
  padding-right: 10px;
  .list-group-item {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.75rem;
    &:hover {
      background-color: #efefef;
    }
    &.selected {
      background-color: theme-color('primary');
      color: white;
      opacity: 0.7;
    }
  }
}

.padding-top {
  padding-top: 20px;
}
.border-top {
  border-top: 1px solid #ddd;
  padding-top: 20px;
}

.margin-top {
  margin-top: 40px;
}

.padding-none {
  padding: 0;
}

.back-button {
  margin: 20px 0;
}

.radea-button {
  margin: 0 10px;
}

.click-item {
  cursor: pointer;
  &.blue {
    color: theme-color('primary');
  }
  &.gray {
    color: gray;
  }
  &.space-around {
    margin: 0 5px;
  }
}

.align-right {
  text-align: right;
}
.align-right-block {
  text-align: right;
  display: block;
}

.list-wrapper {
  h2 {
    margin: 15px 0;
  }
  .nav.nav-tabs {
    margin: 0;
    a.active {
      font-weight: bold;
    }
  }
}

.atgard-container {
  margin-top: 0;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
  select {
    width: 290px;
    margin-right: 15px;
  }
  @media (max-width: 992px) {
    .container {
      flex-direction: column;
      margin: 0;
      .atgard-form {
        margin: 0;
      }
    }
  }
  @media (max-width: 768px) {
    .container .atgard-form .form-row {
      display: block;
    }
  }
}

.aktiviteter-container {
  .dokument-table {
    margin-bottom: 0;
  }
}
.aktivitets-list {
  td.aktivitet {
    min-width: 170px;
  }
  td.dokument {
    min-width: 170px;
  }
}
.wrapper {
  display: flex;
}
td.date {
  min-width: 110px;
}
td span.sort-header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.half-area {
  margin-top: 0;
  width: 50%;
  display: flex;
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 16px;
  border-radius: 4px;
  div.content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    span {
      font-size: 16px;
    }
  }
  &.column {
    flex-direction: column;
  }
  &.no-border {
    border: none;
  }
}
.atgard-form {
  margin-top: 0;
  width: 50%;
  .form-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  select {
    width: 100%;
  }
  @media (max-width: 992px) {
    width: 100%;
  }
}

.max-height-div {
  max-height: 340px;
  overflow-y: scroll;
}

.bottom-item,
.container {
  margin-bottom: 20px;
}

.border-box {
  border: 1px solid #ddd;
  padding: 6px 0;
}

span.anvisning {
  display: flex;
  border: 1px solid #ddd;
  height: 190px;
  border-radius: 3px;
  padding: 1px 3px;
  overflow-y: scroll;
  &.bigger {
    height: 220px;
  }
}

nav.navbar {
  padding: 0.5rem 1.5rem 0.75rem 0.5rem;
  margin-bottom: 15px;
  .container {
    margin-bottom: 0;
  }
  @media screen and (min-width: 992px) {
    #responsive-navbar-nav {
      margin-top: 25px;
    }
  }
  .navbar-brand {
    img {
      height: 50px;
      width: auto;
    }
  }
  .nav-link {
    padding: 0;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      padding: 0;
      padding-right: 3px;
      color: rgba(255, 255, 255, 0.6);
      &:hover,
      &:focus {
        color: rgba(255, 255, 255, 0.75);
      }
    }
  }
  .dropdown {
    .nav-link {
      padding-left: 0;
    }
  }
}
.faktura-dropdown .dropdown-toggle {
  &::after {
    display: none;
  }
}
.navbar-dark .navbar-nav {
  @media (max-width: 992px) {
    align-items: flex-start;
    background-color: rgba(255, 255, 255, 0.15);
    padding: 5px 10px;
    .nav-link {
      font-size: 1.2em;
    }
    &.dropdown-nav {
      flex-direction: row;
      padding-left: 7px;
      border-top: 1px solid rgba(255, 255, 255, 0.5);
    }
  }

  .nav-link {
    color: rgba(255, 255, 255, 0.75);
    &:hover,
    &:focus {
      color: rgba(255, 255, 255, 0.9);
    }
    .dropdown-icon {
      svg {
        color: rgba(255, 255, 255, 0.6);
        &:hover,
        &:focus {
          color: rgba(255, 255, 255, 0.75);
        }
      }
    }
  }
}

.form-card {
  margin-bottom: 30px;
  .card-body {
    display: flex;
    .card-col {
      width: 50%;
      margin-bottom: 12px;
    }
    form {
      width: 100%;
    }
    p.card-text {
      margin-bottom: 0.5rem;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      .card-col {
        width: 100%;
      }
      .form-row {
        flex-direction: column;
      }
      .col-6 {
        max-width: 100%;
      }
    }
  }
}

.pb4 {
  padding-bottom: 4px;
}

/* Tables */
.table thead td {
  font-weight: bold;
  border-top: 0;
}
/* Bootstrap overrides */

.alert.radea-alert {
  box-shadow: rgba(14, 14, 24, 0.2) 0px 7px 29px 0px;
  position: fixed;
  top: 30%;
  left: 50vw;
  z-index: 1000;
  transform: translateX(-50%);
  font-size: 20px;
  max-width: 90vw;

  .main-content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  svg {
    width: 30px;
    margin-right: 10px;
  }

  .fixed-width {
    width: 380px;
  }
}
.btn-success {
  color: #fff;
}
.btn {
  &.btn-small {
    padding: 0;
    margin: 0;
    font-size: 15px;
  }
}
.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: theme-color('primary');
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: theme-color('primary');
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: theme-color('primary');
}

.tab-container {
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-top: 0;
  padding-top: 20px;
}

.row-container {
  display: flex;
  flex-direction: row;
  form:first-child {
    margin-right: 10px;
  }
  form:last-child {
    margin-left: 10px;
  }
}

.button-row {
  display: flex;
  justify-content: space-between;
  &.right {
    justify-content: flex-end;
  }
  &.bottom {
    align-items: flex-end;
  }
  &.fill-width {
    width: 100%;
  }
}
.flex-align-right {
  align-self: flex-end;
}

.bordered-container {
  border: 1px solid #dee2e6;
  margin-top: 20px;
  padding-top: 10px;
}

.bottom-container {
  margin-bottom: 220px;
}

.warning-div {
  color: theme-color('warning');
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 4px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  svg {
    margin: 0 10px 0 5px;
    color: #666;
  }
}

.orderbekraftelse-form {
  width: 100%;
}

.radea-form {
  .input-wrapper {
    display: flex;
    flex-direction: row;
    #skickasDatum {
      flex-grow: 1;
    }
    .react-datepicker__input-container {
      display: inline;
      input[type='text'] {
        border: none;
        background: url(image/calendar.png) no-repeat 1px;
        background-size: 28px;
        width: 34px;
        height: 34px;
        color: transparent;
        cursor: pointer;
      }
    }
  }
  .form-row-col {
    .form-group {
      padding: 0;
    }
    &.add-button-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-bottom: 10px;
      .add-button {
        color: #ddd;
        &.valid {
          cursor: pointer;
          color: theme-color('primary');
        }
      }
    }
  }
  .row-direction {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    div:not(.form-check),
    label {
      display: inline-flex;
    }
    .radea-col {
      flex: 1;
      padding: 10px 0;
    }
  }
  label {
    margin-bottom: 0;
  }
}

.radea-table {
  thead {
    td {
      vertical-align: bottom;
    }
  }
  tbody {
    tr.added {
      background: theme-color('success');
    }
    tr.removed {
      background: theme-color('danger');
    }
    tr.removed,
    tr.added {
      color: white;

      .click-item.blue {
        color: white;
      }
    }
  }
}

.radea-app {
  @media (min-width: 576px) {
    .container,
    .container-sm,
    .container-md {
      max-width: 95vw;
    }
  }
  @media (min-width: 768px) {
    .container,
    .container-sm,
    .container-md {
      max-width: 95vw;
    }
  }
  @media (min-width: 920px) {
    .container,
    .container-sm,
    .container-md {
      max-width: 95vw;
    }
  }
  @media (min-width: 1200px) {
    .container,
    .container-sm,
    .container-md {
      max-width: 1140px;
    }
  }
}

.form-control-changed {
  position: relative;
  padding-right: 5px;

  select,
  textarea,
  input {
    border: 1px solid theme-color('warning');
  }

  &:after {
    content: '';
    background-image: url(escape-svg(
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{theme-color('warning')}' viewBox='0 0 16 16'><path d='M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z'/><path d='M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z'/></svg>"
    ));
    height: 18px;
    width: 18px;
    z-index: 0;
    top: 33px;
    right: calc(0.375em + 0.1875rem);
    display: block;
    position: absolute;
    background-repeat: no-repeat;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
  &.is-select {
    &:after {
      right: 34px;
    }
  }
  &.is-date {
    &:after {
      right: calc(38px + 0.375em + 0.1875rem);
    }
  }
  &.no-label {
    &:after {
      top: 19px;
    }
  }
  &.is-list {
    &:after {
      top: 6px;
    }

    border: 1px solid theme-color('warning');
  }
}

.eg-DatePicker {
  user-select: none;

  .eg-DatePicker-header {
    background: theme-color('primary');
  }
  .eg-DatePicker-year-display {
    color: white;
  }
  .eg-DatePicker-weekdays {
    font-size: 14px;
  }
  .eg-DatePicker-day {
    font-size: 14px;
    &.selected {
      background: theme-color('primary');
    }
    &.is-today {
      border-color: theme-color('primary');
    }
  }
  .eg-DatePicker-outer-day.range {
    &.first > div:not(.selected),
    &.last > div:not(.selected) {
      background: rgba(theme-color('primary'), 0.3);
    }
    &.first:before,
    &.last:before,
    &:last-of-type:before,
    &:first-of-type:before,
    &:before {
      border-top-color: rgba(theme-color('primary'), 0.5);
      border-bottom-color: rgba(theme-color('primary'), 0.5);
      border-left-color: rgba(theme-color('primary'), 0.5);
      border-right-color: rgba(theme-color('primary'), 0.5);
    }
  }
  .eg-DatePicker-week {
    &:before {
      color: theme-color('extra');
    }
  }
}
button.icon-button {
  svg {
    margin-bottom: 2px;
    &:first-child {
      margin-right: 3px;
    }
    &:last-child {
      margin-left: 3px;
    }
  }
}

.modal {
  &.responsive {
    width: 100vw;
    height: 100vh;
  }
}
